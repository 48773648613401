v<!--
 * @Desc: 描述
 * @Date: 2020-01-10 22:52:17
 * @LastEditTime: 2020-06-21 14:36:47
 -->
<template>
  <div class="admin-index">
    <table
      class="admin-index-table"
      border="1"
      cellspacing="0"
      cellspadding="10"
    >
      <tbody>
        <tr class="hd">
          <td width="25%">注册队伍总数</td>
          <td width="25%">未提交审核总数</td>
          <td width="25%">待审核总数</td>
          <td width="25%">审核通过总数</td>
        </tr>
        <tr class="bd">
          <td width="25%">{{ info.registered }}</td>
          <td width="25%">{{ info.unsubmitted }}</td>
          <td width="25%">{{ info.pending }}</td>
          <td width="25%">{{ info.pass }}</td>
        </tr>
      </tbody>
    </table>
    <p class="hasReadTitle">数据统计</p>
    <table
      class="admin-index-table"
      border="1"
      cellspacing="0"
      cellspadding="10"
    >
      <tbody>
        <tr class="hd">
          <td width="25%">运动员人数/已审核人数</td>
          <td width="25%">男运动员/已审核</td>
          <td width="25%">女运动员/已审核</td>
          <td width="25%"></td>
        </tr>
        <tr class="bd">
          <td width="25%">{{ info.participantSum }} / {{ info.sex }}</td>
          <td width="25%">{{ info.maleSum }} / {{ info.male }}</td>
          <td width="25%">{{ info.femaleSum }} / {{ info.female }}</td>
          <td width="25%"></td>
        </tr>
        <tr class="hd">
          <td width="25%">武术报项数/已审核总数</td>
          <td width="25%">个人报项/已审核</td>
          <td width="25%">集体报项/已审核</td>
          <!-- <td width="25%">对练报项/已审核</td> -->
          <td width="25%">太极推手/已审核</td>
        </tr>
        <tr class="bd">
          <td width="25%">{{ info.projectSum }} / {{ info.project }}</td>
          <td width="25%">{{ info.personalSum }} / {{ info.personal }}</td>
          <td width="25%">{{ info.collectiveSum }} / {{ info.collective }}</td>
          <!-- <td width="25%">{{ info.duelSum }} / {{ info.duel }}</td> -->
          <td width="25%">{{ info.kickboxingSum }} / {{ info.kickboxing }}</td>
        </tr>
      </tbody>
    </table>
    <table
      v-if="info.projectList"
      class="admin-index-table"
      border="1"
      cellspacing="0"
      cellspadding="10"
    >
      <tbody>
        <tr class="hd">
          <td width="35%">项目名称</td>
          <td width="15%">项目类型</td>
          <td width="25%">总数</td>
          <td width="25%">已审核数</td>
        </tr>

        <tr class="bd">
          <td>太极类</td>
          <td>个人</td>
          <td>{{ info.projectListSum.taijiType }}</td>
          <td>{{ info.projectList.taijiType }}</td>
        </tr>
        <tr class="bd">
          <td>南拳类</td>
          <td>个人</td>
          <td>{{ info.projectListSum.nanquanType }}</td>
          <td>{{ info.projectList.nanquanType }}</td>
        </tr>
        <tr class="bd">
          <td>长拳类</td>
          <td>个人</td>
          <td>{{ info.projectListSum.changquanType }}</td>
          <td>{{ info.projectList.changquanType }}</td>
        </tr>

        <tr class="bd">
          <td>集体项目</td>
          <td>集体</td>
          <td>{{ info.projectListSum.jtxm }}</td>
          <td>{{ info.projectList.jtxm }}</td>
        </tr>

        <tr class="bd">
          <td>太极推手</td>
          <td>个人</td>
          <td>{{ info.projectListSum.kickboxing }}</td>
          <td>{{ info.projectList.kickboxing }}</td>
        </tr>
      </tbody>
    </table>
    <div style="text-align:center">
      <el-button
        class="hasReadTitle"
        :loading="downloading"
        @click="outPutInfo()"
        >导出所有已审核数据</el-button
      >
      <el-button
        class="hasReadTitle"
        :loading="downloading"
        @click="outPutTeamProjectSummary()"
        >导出队伍报项汇总</el-button
      >
    </div>
  </div>
</template>

<script>
import { getAllCount, outputPassInfoAdmin, outputProjectSummary } from "@/api";

export default {
  name: "adminIndex",
  props: {},
  data() {
    return {
      info: {},
      downloading: false
    };
  },
  computed: {},
  created() {
    getAllCount()
      .then(res => {
        this.info = res.data;
      })
      .catch(err => {
        console.error(err);
      });
  },
  mounted() {},
  watch: {},
  methods: {
    outPutInfo() {
      this.downloading = true;
      outputPassInfoAdmin()
        .then(res => {
          if (res.status === 200) {
            this.downloading = false;
            let blob = new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
            let downloadElement = document.createElement("a");
            let href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = "审核通过报名名单.xlsx"; //下载后文件名：队伍名+报名详情
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
          }
        })
        .catch(err => {
          console.error(err);
          this.downloading = false;
        });
    },
    outPutTeamProjectSummary() {
      this.downloading = true;
      outputProjectSummary()
        .then(res => {
          if (res.status === 200) {
            this.downloading = false;
            let blob = new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
            let downloadElement = document.createElement("a");
            let href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = "队伍报项费用汇总.xlsx"; //下载后文件名：队伍名+报名详情
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
          }
        })
        .catch(err => {
          console.error(err);
          this.downloading = false;
        });
    }
  },
  components: {}
};
</script>

<style scoped lang="scss">
.admin-index {
  padding: 30px;
}
.admin-index-table {
  width: 100%;
  max-width: 800px;
  margin: 30px auto;
  border-collapse: collapse;
  line-height: 2.5;
  text-align: center;
  td {
    padding: 10px 0px;
  }
  .hd {
    background: #e9eaec;
  }
  .bd {
    background: #fefefe;
  }
}
.hasReadTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  font-family: "Times New Roman";
}
</style>
